import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/Humanity/2023cls6to7/1.jpg';
import p2 from 'assests/Photos/Clubs/Humanity/2023cls6to7/2.jpg';
import p3 from 'assests/Photos/Clubs/Humanity/2023cls6to7/3.jpg';
import p4 from 'assests/Photos/Clubs/Humanity/2023cls6to7/4.jpg';
import p5 from 'assests/Photos/Clubs/Humanity/2023cls6to7/5.jpg';
import p6 from 'assests/Photos/Clubs/Humanity/2023cls6to7/6.jpg';
import p7 from 'assests/Photos/Clubs/Humanity/2023cls6to7/7.jpg';
import p8 from 'assests/Photos/Clubs/Humanity/2023cls6to7/8.jpg';


// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';
import HumanityClub from 'views/HumanityClub';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';


const HumanityClass6to72023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 2,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 2,
      },
      {
        src: p7,
        source: p7,
        rows: 2,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Kala Kriti - An Ethnic Art Fiesta
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 6 - 7  &  Date: 30 JUNE 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'center'}>
        “We are all artists, painting our lives upon the canvas of reality.” –Cristen Rodgers
        </Typography>

        <Typography variant={'subtitle1'} align={'justify'}>
        <br></br>
        Humanities Association of National Public School, Yeshwanthpur organized KALA KRITI-AN ETHNIC ART FIESTA on 
        30 June 2023 for the students of classes 6 and 7.  Art enhances creativity, which in turn fosters problem-solving 
        skills. It helps children develop confidence in their abilities and learn new things. Art and craft have the power 
        to improve psychomotor skills. It strengthens their muscles and stimulates the coordination of all their senses. 
        <br></br>
        The activity aimed to raise awareness among children about Indian ethnic art and the lives of artisans and 
        craftsmen. The objective was impressively attained as the students designed and painted their table cloth with 
        beautiful patterns of Warli, Kalamkari, Patola and Palampore art with a unique and imaginative twist of their own. 
        The activity highlighted the significance of effective teamwork and trust in each other’s abilities.
        <br></br>
        It was truly an enriching and enjoyable experience for the students.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “AS THE SUN COLOURS FLOWERS, SO DOES ART COLOUR LIFE”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 400 : 250}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default HumanityClass6to72023;